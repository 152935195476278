<template>
  <div>
    <v-alert v-model="alert" dismissible border="left" type="success">{{
      message
    }}</v-alert>
    <v-toolbar flat>
      <v-toolbar-title>Versions</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2">Fetch</v-btn>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="success" dark @click="save" class="mb-2">Save</v-btn>
    </v-toolbar>
    <v-row>
      <v-col cols="12" md="2">
        <v-text-field
          v-model="versionDto.version"
          label="Name"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="error" dark @click="addVersion" class="mb-2">Add</v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-combobox
          v-model="currentVersion"
          :items="_.sortBy($store.state.apis.versions, 'version').reverse()"
          item-text="version"
          item-value="version"
          label="Current Version"
          hide-selected
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-if="item === Object(item)"
              v-bind="attrs"
              :color="`green darken-2`"
              :input-value="selected"
              label
              small
            >
              <span class="pr-2">{{ item.version }}</span>
              <v-icon small @click="parent.selectItem(item)">close</v-icon>
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <div
          v-for="(item, a) in $store.state.apis.all.slice(0, 1)"
          :key="a - 10"
        >
          <v-card>
            <v-toolbar flat color="blue-grey" dark>
              <v-toolbar-title>Versions</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-combobox
                v-model="versions"
                :items="_.sortBy(versions, 'version').reverse()"
                item-text="version"
                item-value="version"
                label="Version"
                hide-selected
                multiple
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :color="`green darken-2`"
                    :input-value="selected"
                    label
                    small
                  >
                    <span class="pr-2">{{ item.version }}</span>
                    <v-icon small @click="parent.selectItem(item)"
                      >close</v-icon
                    >
                  </v-chip>
                </template>
              </v-combobox>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import Api from "@/store/modules/baseApi";

export default {
  name: "app",
  components: {},
  data() {
    return {
      currentVersion: {},
      versions: [],
      versionDto: {},
      alert: false,
      message: "",
    };
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("apis/getAll");
      await this.$store.dispatch("apis/getAllVersions");
      this.versions = this.$store.state.apis.versions.filter(
        (m) => m.id != "currentVersion"
      );
      this.currentVersion = this.$store.state.apis.versions.find(
        (m) => m.id == "currentVersion"
      );
    },
    async addVersion() {
      this.versionDto.version = parseFloat(this.versionDto.version);
      this.versions.push({
        id: this.versionDto.version.toString(),
        version: this.versionDto.version,
      });
      this.$store.state.apis.versions.push({
        id: this.versionDto.version.toString(),
        version: this.versionDto.version,
      });

      await this.$store.dispatch("apis/updateVersion", {
        id: this.versionDto.version.toString(),
        ...this.versionDto,
      });

      this.alert = true;
      let that = this;
      this.message = this.versionDto.version + " Added Version...";
      this.versionDto = {};
      setTimeout(function() {
        that.alert = false;
      }, 3000);
    },
    async save() {
      await this.$store.dispatch("apis/updateVersion", {
        id: "currentVersion",
        version: this.currentVersion.version,
      });

      // eslint-disable-next-line no-console
      console.log(this.versions);
      await this.$store.dispatch(`apis/updateAllVersion`, [
        ...this.versions,
        {
          id: "currentVersion",
          version: this.currentVersion.version,
        },
      ]);

      // this.$store.state.apis.all.forEach(async (doc) => {
      //   await Api.post(`https://${doc.name}.restplay.com/Devops/Version`, {
      //     versions: doc.versions,
      //     currentVersion: this.currentVersion.version,
      //   });
      // });
    },
    async mounted() {
      await this.initialize();
    },
  },
};
</script>
<style>
.jsoneditor-field {
  color: #b7b3c3 !important;
}
a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
  color: #00cfff !important;
}
div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
  color: #b7abab !important;
}
div.jsoneditor-readonly {
  color: #c7c1c1;
}
div.jsoneditor-value.jsoneditor-string {
  color: #00cfff;
}
</style>
